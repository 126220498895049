define("discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-counter", ["exports", "virtual-dom", "discourse/lib/icon-library", "discourse/widgets/widget", "discourse/plugins/discourse-reactions/discourse/models/discourse-reactions-custom-reaction"], function (_exports, _virtualDom, _iconLibrary, _widget, _discourseReactionsCustomReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-reactions-counter", {
    tagName: "div",
    buildKey: attrs => `discourse-reactions-counter-${attrs.post.id}-${attrs.position || "right"}`,
    buildId: attrs => `discourse-reactions-counter-${attrs.post.id}-${attrs.position || "right"}`,
    reactionsChanged(data) {
      data.reactions.uniq().forEach(reaction => {
        this.getUsers(reaction);
      });
    },
    defaultState() {
      return {
        reactionsUsers: {},
        statePanelExpanded: false
      };
    },
    getUsers(reactionValue) {
      return _discourseReactionsCustomReaction.default.findReactionUsers(this.attrs.post.id, {
        reactionValue
      }).then(response => {
        response.reaction_users.forEach(reactionUser => {
          this.state.reactionsUsers[reactionUser.id] = reactionUser.users;
        });
        this.scheduleRerender();
        this.callWidgetFunction("updatePopperPosition");
      });
    },
    mouseDown(event) {
      event.stopImmediatePropagation();
      return false;
    },
    mouseUp(event) {
      event.stopImmediatePropagation();
      return false;
    },
    click(event) {
      this.callWidgetFunction("cancelCollapse");
      if (!this.capabilities.touch || !this.site.mobileView) {
        event.stopPropagation();
        event.preventDefault();
        if (!this.attrs.statePanelExpanded) {
          this.getUsers();
        }
        this.toggleStatePanel(event);
      }
    },
    clickOutside() {
      if (this.attrs.statePanelExpanded) {
        this.callWidgetFunction("collapseAllPanels");
      }
    },
    touchStart(event) {
      this.callWidgetFunction("cancelCollapse");
      if (event.target.classList.contains("show-users") || event.target.classList.contains("avatar")) {
        return true;
      }
      if (this.attrs.statePanelExpanded) {
        event.stopPropagation();
        event.preventDefault();
        return;
      }
      if (this.capabilities.touch) {
        event.stopPropagation();
        event.preventDefault();
        this.getUsers();
        this.toggleStatePanel(event);
      }
    },
    buildClasses(attrs) {
      const classes = [];
      const mainReaction = this.siteSettings.discourse_reactions_reaction_for_like;
      if (attrs.post.reactions && attrs.post.reactions.length === 1 && attrs.post.reactions[0].id === mainReaction) {
        classes.push("only-like");
      }
      if (attrs.post.reaction_users_count > 0) {
        classes.push("discourse-reactions-counter");
      }
      return classes;
    },
    html(attrs) {
      if (attrs.post.reaction_users_count) {
        const post = attrs.post;
        const count = post.reaction_users_count;
        if (count <= 0) {
          return;
        }
        const mainReaction = this.siteSettings.discourse_reactions_reaction_for_like;
        const mainReactionIcon = this.siteSettings.discourse_reactions_like_icon;
        const items = [];
        items.push(this.attach("discourse-reactions-state-panel", Object.assign({}, attrs, {
          reactionsUsers: this.state.reactionsUsers
        })));
        if (!(post.reactions.length === 1 && post.reactions[0].id === mainReaction)) {
          items.push(this.attach("discourse-reactions-list", {
            reactionsUsers: this.state.reactionsUsers,
            post: attrs.post
          }));
        }
        items.push((0, _virtualDom.h)("span.reactions-counter", count.toString()));
        if (post.yours && post.reactions && post.reactions.length === 1 && post.reactions[0].id === mainReaction) {
          items.push((0, _virtualDom.h)("div.discourse-reactions-reaction-button.my-likes", (0, _virtualDom.h)("button.btn-toggle-reaction-like.btn-icon.no-text.reaction-button", [(0, _iconLibrary.iconNode)(`${mainReactionIcon}`)])));
        }
        return items;
      }
    },
    toggleStatePanel() {
      if (!this.attrs.statePanelExpanded) {
        this.callWidgetFunction("expandStatePanel");
      } else {
        this.callWidgetFunction("collapseStatePanel");
      }
    },
    mouseOver() {
      this.callWidgetFunction("cancelCollapse");
    },
    mouseOut(event) {
      if (!event.relatedTarget?.closest(`#${this.buildId(this.attrs)}`)) {
        this.callWidgetFunction("scheduleCollapse", "collapseStatePanel");
      }
    }
  });
});